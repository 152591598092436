<template>
  <div class="pcm-app">
    <div class="main">
      <div class="headerBox"></div>
      <div v-if="showImg">
        <img
          v-for="(item, index) in urlList"
          :key="index"
          :src="item.url"
          width="1"
          height="1"
          @error="autoTest(item, index)"
          style="display: none"
        />
      </div>
      <div class="appArea">
        <div
          v-for="(item, index) in urlList"
          :key="item.url"
          @click="jumpUrl(item.url)"
        >
          <div class="appBox" v-if="index < 5">
            <img :src="item.icon" class="appBg" />
            <div class="timeConsuming">
              <span v-if="item.time == 0">检测中...</span
              ><span v-else
                ><span v-if="item.time < 50" class="green"
                  >{{ item.time }}MS</span
                >
                <span v-if="item.time >= 50 && item.time < 100" class="orange"
                  >{{ item.time }}MS</span
                >
                <span v-if="item.time >= 100" class="red"
                  >{{ item.time }}MS</span
                ></span
              >
            </div>
          </div>
          <div class="colAppBox" v-else>
            <img :src="item.icon" class="colAppBg" />
            <div class="colTimeConsuming">
              <span v-if="item.time == 0">检测中...</span
              ><span v-else
                ><span v-if="item.time < 50" class="green"
                  >{{ item.time }}MS</span
                >
                <span v-if="item.time >= 50 && item.time < 100" class="orange"
                  >{{ item.time }}MS</span
                >
                <span v-if="item.time >= 100" class="red"
                  >{{ item.time }}MS</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="joinBtn" @click="jumpUrl('https://t.me/jackmomn')"></div>
        <div class="refreshBtn" @click="reset"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from "./../../../mixin/index";
export default {
  name: "mobile",
  mixins: [mixins],
};
</script>
<style lang="scss" scoped>
.pcm-app {
  height: 100%;
  width: 375px;
  margin: 0 auto;
  overflow-y: auto;
  background: black;
  .main {
    height: 1072px;
    padding: 0px 16px;
    background: url("./../../../assets/png/mobileBg.webp") no-repeat;
    background-size: 100% 100%;
    .headerBox {
      height: 212px;
    }
    .appArea {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .appBox {
        position: relative;
        height: 64px;
        width: 343px;
        margin-bottom: 12px;
        border-radius: 6px;
        cursor: pointer;
        .appBg {
          height: 100%;
          width: 100%;
        }
        .timeConsuming {
          color: #31ffe4;
          font-size: 12px;
          position: absolute;
          top: 8px;
          right: 20px;
          width: 50px;
          text-align: center;
        }
        .green {
          color: #31ffe4;
        }
        .orange {
          color: #f5a937;
        }
        .red {
          color: #e93333;
        }
      }
      .colAppBox {
        position: relative;
        height: 64px;
        width: 165px;
        margin-bottom: 12px;
        cursor: pointer;
        .colAppBg {
          height: 100%;
          width: 100%;
        }
        .colTimeConsuming {
          color: #31ffe4;
          font-size: 12px;
          position: absolute;
          bottom: 14.5px;
          left: 56px;
        }
        .green {
          color: #31ffe4;
        }
        .orange {
          color: #f5a937;
        }
        .red {
          color: #e93333;
        }
      }
    }
    .bottomBox {
      height: 90px;
      background: url("./../../../assets/png/bottomBox.webp") no-repeat;
      background-size: 100% 100%;
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      width: 375px;
      .joinBtn {
        position: absolute;
        top: 10px;
        left: 50%;
        height: 41px;
        width: 343px;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
      .refreshBtn {
        position: absolute;
        bottom: 10px;
        left: 50%;
        height: 20px;
        width: 213px;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
  }
}
</style>
